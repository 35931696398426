<template lang="html">
  <LoadingWrapper :loading="loading">
    <div class="employees">
      <div class="employees-header">
        <DlgHeader>
          <IxRes>dlgCert.social.employees.headers.currentEmployees</IxRes>
        </DlgHeader>
        <a href="#previous-employees" class="previous-employees-link">
          <IxRes>dlgCert.social.employees.headers.goToPreviousEmployees</IxRes>
        </a>
      </div>

      <SocialEmployeeCard
        v-for="employee in currentPersonal" :key="employee.id"
        :employee="employee"
        @input="updateEmployee"
        @remove="removeEmployee"
      />

      <IxButton
        class="add-button" colored
        add large
        @click="addNewEmployee"
      >
        <IxRes>dlgCert.social.employees.buttons.add</IxRes>
      </IxButton>

      <hr>
      <DlgHeader id="previous-employees">
        <IxRes>dlgCert.social.employees.headers.previousEmployees</IxRes>
      </DlgHeader>

      <SocialEmployeeCard
        v-for="employee in previousEmployees" :key="employee.id"
        :employee="employee"
        @input="updateEmployee"
        @remove="removeEmployee"
      />
    </div>
  </LoadingWrapper>
</template>

<script>
import {partition} from 'lodash'

import IxButton from '@components/IxButton'

import LoadingWrapper from './shared/LoadingWrapper'
import DlgHeader from './elements/DlgHeader'
import SocialEmployeeCard from './elements/SocialEmployeeCard'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    IxButton,
    LoadingWrapper,
    DlgHeader,
    SocialEmployeeCard
  },
  mixins: [
    ApiMixin
  ],
  data () {
    return {
      count: 0
    }
  },
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/social/${this.$route.params.year}/employees`
    },
    partitionedEmployees () {
      if (!this.data) return [[], []]

      const now = new Date().toISOString()
      return partition(this.data.employees, x => x.employmentEnd && x.employmentEnd < now)
    },
    currentEmployers () {
      const managers = this.partitionedEmployees[1].filter(x => x.employment === 'manager')
      if (managers.length > 0) return managers
      if (!this.data) return []

      this.addNewEmployer()
      return []
    },
    currentEmployees () {
      return this.partitionedEmployees[1].filter(x => x.employment !== 'manager')
    },
    currentPersonal () {
      return this.currentEmployers.concat(this.currentEmployees)
    },
    previousEmployees () {
      return this.partitionedEmployees[0]
    }
  },
  methods: {
    async addNewEmployee () {
      const employee = (await this.api.post()).data

      this.data.employees.push(employee)
    },
    async addNewEmployer () {
      const employer = (await this.api.post('manager')).data

      this.data.employees.push(employer)
    },
    async updateEmployee (employee) {
      const {id} = employee
      // TODO remove comment when backend works
      // const old = this.data.employees.find(x => x.id === id)

      this.data.employees.splice(this.data.employees.findIndex(x => x.id === id), 1, employee)

      try {
        await this.api.put(`${employee.id}`, employee)
      } catch (error) {
        console.error(error)
        // TODO remove comment when backend works
        // this.data.employees.splice(this.data.employees.findIndex(x => x.id === id), 1, old)
      }
    },
    async removeEmployee (employee) {
      const {id} = employee

      this.data.employees.splice(this.data.employees.findIndex(x => x.id === id), 1)

      try {
        await this.api.delete(`${id}`, employee)
      } catch (error) {
        console.error(error)
        // TODO remove comment when backend works
        // this.data.employees.push(employee)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.employees {
  display: flex;
  flex-direction: column;
  flex: 1;
}

hr {
  margin: 1em 0;
}

.employees-header {
  display: flex;

  .previous-employees-link {
    margin: 10px;
  }
}

.add-button {
  align-self: flex-start;
}
</style>
