<template lang="html">
  <div class="employee-card">
    <div class="card-header">
      <div class="name-header">
        <div class="name-label">
          <IxRes v-if="employee.employment === 'manager'">dlgCert.social.employees.headers.manager</IxRes>
          <IxRes v-else>dlgCert.social.employees.headers.employee</IxRes>
          <span>:</span>
        </div>
        <span class="name">{{ employee.name }}</span>
      </div>

      <template v-if="employee.employment !== 'manager'">
        <a href="#" class="remove" @click.prevent="$emit('remove', employee)">
          <FontAwesomeIcon :icon="icons.remove" />
          <IxRes>dlgCert.social.employees.buttons.remove</IxRes>
        </a>
      </template>
    </div>

    <hr>

    <SocialEmployeeCardForm v-model="modelProxy" />
  </div>
</template>

<script>
import {modelProxy} from '@helpers/vuex'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faTrash} from '@fortawesome/free-solid-svg-icons'

import SocialEmployeeCardForm from './SocialEmployeeCardForm'

export default {
  components: {
    FontAwesomeIcon,
    SocialEmployeeCardForm
  },
  model: {
    prop: 'employee'
  },
  props: {
    employee: Object
  },
  computed: {
    modelProxy,
    icons () {
      return {
        remove: faTrash
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.employee-card {
  width: 60%;

  display: flex;
  flex-direction: column;
  padding: 0.5em 1em;

  box-shadow: rgba(#000000, 0.4) 1px 2px 4px 0px;
  border-radius: 5px;

  &:not(:last-child) {
    margin-bottom: 1.5em;
  }
}

hr {
  margin: 0.5em -1em;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .name-header {
    display: flex;

    > .name-label {
      text-transform: uppercase;
      font-weight: bold;
    }

    > .name {
      margin-left: 0.25em;
    }
  }
}

.remove {
  svg {
    font-size: 0.8em;
    margin-right: 0.2em;
  }
}
</style>
