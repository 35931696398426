<script>
import TextInput from '@components/forms/TextInput'

export default {
  functional: true,
  render (h, context) {
    return h('div', {
      class: 'dlg-text-input'
    }, [h(TextInput, context.data, context.children)])
  }
}
</script>

<style lang="scss">
.dlg-text-input {
  input.form-control {
    height: 24px;
    font-size: 14px;
    padding: 2px 4px;
  }

  span.input-group-addon {
    padding: 2px 4px;
    border: none;
    background-color: transparent;
  }

  // span.help-block {
  //   display: none;
  // }
}
</style>
