<template lang="html">
  <div class="employee-form">
    <div class="id-column">
      <DlgTextInput v-model="name">
        <template #label>
          <IxRes>dlgCert.social.employees.labels.name</IxRes>
        </template>
      </DlgTextInput>

      <DlgTextInput v-model="employeeId">
        <template #label>
          <IxRes>dlgCert.social.employees.labels.employeeId</IxRes>
        </template>
      </DlgTextInput>
    </div>
    <div class="employment-basics">
      <EmploymentRangePicker :value="{start: employmentStart, end: employmentEnd}" @input="updateEmploymentRange">
        <template #label>
          <IxRes>dlgCert.social.employees.labels.employmentStartEnd</IxRes>
        </template>
      </EmploymentRangePicker>

      <BsCheckbox v-model="isExternal">
        <IxRes>dlgCert.social.employees.labels.external</IxRes>
      </BsCheckbox>
      <BsCheckbox v-if="employment == 'manager'" v-model="isEmployer">
        <IxRes>dlgCert.social.employees.labels.isEmployer</IxRes>
      </BsCheckbox>
    </div>
    <div class="fixedTerm">
      <BsRadioList v-if="employment !== 'manager'" v-model="isFixedTerm" :options="[false, true]">
        <template #default="{option}">
          <IxRes>dlgCert.social.employees.labels.fixedTerm_{{ option }}</IxRes>
        </template>

        <template #label>
          <IxRes>dlgCert.social.employees.labels.fixedTerm</IxRes>
        </template>
      </BsRadioList>
      <DlgVueSelectize v-model="workdays" :options="[1, 2, 3, 4, 5, 6]">
        <template #label>
          <IxRes>dlgCert.social.employees.labels.workdays</IxRes>
        </template>
      </DlgVueSelectize>
    </div>
    <div class="employment">
      <BsRadioList v-if="employment !== 'manager'" v-model="employment" :options="employmentOptions">
        <template #default="{option}">
          <IxRes>dlgCert.social.employees.labels.employment_{{ option }}</IxRes>
        </template>

        <template #label>
          <IxRes>dlgCert.social.employees.labels.employment</IxRes>
        </template>
      </BsRadioList>
    </div>
  </div>
</template>

<script>
import {mapFormFieldsFromValue} from '@helpers/vue'

import BsRadioList from '@components/bootstrap/BsRadioList'
import BsCheckbox from '@components/bootstrap/BsCheckbox'

import DlgTextInput from './DlgTextInput'
import DlgVueSelectize from './DlgVueSelectize'
import EmploymentRangePicker from './EmploymentRangePicker'

export default {
  components: {
    BsRadioList,
    BsCheckbox,
    EmploymentRangePicker,
    DlgTextInput,
    DlgVueSelectize
  },
  props: {
    value: Object
  },
  computed: {
    ...mapFormFieldsFromValue([
      'name',
      'employeeId',
      'isExternal',
      'isFixedTerm',
      'isEmployer',
      'employment',
      'employmentStart',
      'employmentEnd',
      'workdays'
    ]),
    employmentOptions () {
      return [
        'fullTime',
        'partTime',
        'trainee',
        'temp'
      ]
    }
  },
  methods: {
    updateEmploymentRange ({start: employmentStart, end: employmentEnd}) {
      this.$emit('input', {
        ...this.value,
        employmentStart,
        employmentEnd
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.employee-form {
  display: flex;

  > * {
    flex: 1;
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-left: 1em;
    }
  }

  ::v-deep .selectize-control {
    width: 60px;
  }
}
</style>
