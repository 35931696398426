<template lang="html">
  <LabelAndMessage :id="id" :label="label">
    <div :class="{horizontal}">
      <template v-if="showToggleAll && Array.isArray(value)">
        <ToggleAllBsCheckbox v-model="modelProxy" :options="rawOptions" :compact="compact" />
        <hr>
      </template>

      <component
        :is="component"
        v-for="(option, index) in options" :key="rawOptions[index]"
        v-model="modelProxy" :value="rawOptions[index]"
        :disabled="option.disabled" :compact="compact"
      >
        <strong v-if="isOptionSelected(option)">
          <slot :option="option">
            <IxRes>{{ option.label || option }}</IxRes>
          </slot>
        </strong>
        <span v-else>
          <slot :option="option">
            <IxRes>{{ option.label || option }}</IxRes>
          </slot>
        </span>
      </component>
    </div>
    <slot slot="label" name="label" />
  </LabelAndMessage>
</template>

<script>
import {modelProxy} from '@helpers/vuex'

import ToggleAllBsCheckbox from '@components/bootstrap/ToggleAllBsCheckbox'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

import ChangeDetectionMixin from '@components/forms/ChangeDetectionMixin'

let counter = 0

export default {
  components: {
    ToggleAllBsCheckbox,
    LabelAndMessage
  },
  mixins: [
    ChangeDetectionMixin
  ],
  props: {
    name: String,
    value: {},
    resetValue: {},
    options: Array, // [{value, label}, ...] OR [value, ...]
    horizontal: Boolean,
    compact: Boolean,
    showToggleAll: Boolean,
    label: String,
    component: Object
  },
  data () {
    return {
      id: `option-list-${counter++}`
    }
  },
  computed: {
    modelProxy,
    rawOptions () {
      return this.options.map(this.getRawValue)
    },
    allSelected () {
      return this.options.length === this.value.length
    }
  },
  methods: {
    getRawValue (option) {
      return option.value !== undefined ? option.value : option
    },
    isOptionSelected (option) {
      const value = this.getRawValue(option)

      return Array.isArray(this.value)
        ? this.value.includes(value)
        : this.value === value
    }
  }
}
</script>

<style lang="scss" scoped>
.horizontal {
  display: flex;
  flex-wrap: wrap;
  > * {
    margin-right: 20px;
  }
}

hr {
  margin: 0;
}
</style>
