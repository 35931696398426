<template lang="html">
  <BsCheckbox v-model="allSelected" v-bind="remainingProps" v-on="remainingListeners">
    <slot>
      <IxRes v-if="allSelected === false">
        Common.SR_Common.selectAll
      </IxRes>
      <IxRes v-else>
        Common.SR_Common.deselectAll
      </IxRes>
    </slot>
  </BsCheckbox>
</template>

<script>
import BsCheckbox from '@components/bootstrap/BsCheckbox'

export default {
  components: {
    BsCheckbox
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  computed: {
    remainingProps () {
      // eslint-disable-next-line no-unused-vars
      const {value, options, ...rest} = this.$props

      return rest
    },
    remainingListeners () {
      // eslint-disable-next-line no-unused-vars
      const {input, ...rest} = this.$listeners

      return rest
    },
    allSelected: {
      get () {
        let allSelected = true
        let atLeastOneSelected = false
        for (const option of this.options) {
          if (this.value.indexOf(option) === -1) {
            allSelected = false
          } else {
            atLeastOneSelected = true
          }
        }
        if (allSelected) {
          return true
        } else if (atLeastOneSelected) {
          return null
        } else {
          return false
        }
      },
      set (value) {
        this.$emit('input', value ? [...this.options] : [])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
