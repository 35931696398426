<template lang="html">
  <LabelAndMessage class="range-picker" :message="message">
    <template #label>
      <slot name="label" />
    </template>
    <div class="date-range">
      <div class="start">
        <IxRes>dlgCert.social.employees.labels.employmentStart</IxRes>
        <VueDateTimePicker
          :value="value.start" dont-use-current-date
          :max="value.end" format="MM/YYYY"
          iso
          :name="`${name}-start`"
          @input="emitStart"
        />
      </div>

      <div class="end">
        <BsCheckbox v-model="allowEnd">
          <IxRes>dlgCert.social.employees.labels.employmentEnd</IxRes>
        </BsCheckbox>
        <VueDateTimePicker
          :value="value.end" dont-use-current-date
          :min="value.start" format="MM/YYYY"
          iso :readonly="!allowEnd"
          :name="`${name}-end`"
          @input="emitEnd"
        />
      </div>
    </div>
  </LabelAndMessage>
</template>

<script>
import BsCheckbox from '@components/bootstrap/BsCheckbox'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import moment from 'moment'

export default {
  components: {
    BsCheckbox,
    VueDateTimePicker,
    LabelAndMessage
  },
  props: {
    value: {},
    name: String,
    label: String,
    message: String
  },
  data () {
    return {
      allowEnd: !!this.value.end
    }
  },
  methods: {
    emitStart (start) {
      const startAsLocal = moment.utc(start).local().toISOString(true)
      this.$emit('input', {start: startAsLocal, end: this.value.end})
    },
    emitEnd (end) {
      const endAsLocal = moment.utc(end).local().toISOString(true)
      this.$emit('input', {start: this.value.start, end: endAsLocal})
    }
  },
  watch: {
    allowEnd (value) {
      if (!value && this.value.end) {
        this.emitEnd(null)
      }
    },
    'value.end': {
      immediate: true,
      handler (value) {
        this.allowEnd = !!value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.range-picker {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.date-range {
  display: flex;
  flex-direction: column;

  .start, .end {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > :last-child {
      width: 7em;
      margin-left: 0.5em;
    }
  }
}

::v-deep {
  input.form-control {
    height: 24px;
    font-size: 14px;
    padding: 2px 4px;
  }

  span.input-group-addon {
    padding: 2px 4px;
  }

  .clear-button {
    top: 1px;
    right: 23px !important;
  }
}
</style>
